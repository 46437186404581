import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { z } from 'zod'
import { ApiError, client } from './user-platform-api-client'
import {
  CreatePatchRequest,
  CreateScanRequestFormData,
  CreateScanResponse,
  Credentials,
  GitHubRepositoryResponse,
  HALPaginatedAzureOrganizations,
  HALPaginatedAzureProjects,
  HALPaginatedAzureRepositories,
  PaginatedAnalysisResponse,
  PaginatedChangesets,
  PaginatedFindings,
  PaginatedFixes,
  PaginatedResponseRepositoryResponse,
  RepositoryResponse,
  ScanAnalysis,
  User,
} from './user-platform-api-schemas'
import type { components } from './user-platform-api.d.ts'

type ShapesMatch<T, U> = [T] extends [U] ? ([U] extends [T] ? true : false) : false
type TypesMatch<T, U> =
  ShapesMatch<T, U> extends true ? (ShapesMatch<keyof T, keyof U> extends true ? true : false) : false
type Expect<T extends true> = T

export const useGetUser = ({ enabled = true }: { enabled?: boolean } = {}) =>
  useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      const { data } = await client.GET('/api/user')
      return User.parse(data)
    },
    staleTime: 300_000, // 5 min
    enabled,
  })

// @ts-expect-error: TS expect error because API does not include type
type name1 = Expect<TypesMatch<RepositoryResponse, components['schemas']['RepositoryResponse']>>
// @ts-expect-error: TS expect error because API is not generated with the correct case, nor does it include the type
type name2 = Expect<TypesMatch<GitHubRepositoryResponse, components['schemas']['GitHubRepositoryResponse']>>
export const useGetRepositoriesV1 = ({ pageNumber, pageSize }: { pageNumber: number; pageSize: number }) => {
  let { data, ...rest } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ['repositoriesV1', pageNumber, pageSize],
    queryFn: async () => {
      const { data } = await client.GET('/api/v1/repositories', {
        params: { query: { page_number: pageNumber, page_size: pageSize } },
      })

      return PaginatedResponseRepositoryResponse.parse(data)
    },
  })

  data = useMemo(() => data ?? { total: 0, page: { number: 0, size: 0 }, items: [] }, [data])
  return { data, ...rest }
}

export const useGetScanAnalysisV1 = ({ analysisId, enabled = true }: { analysisId: string; enabled?: boolean }) => {
  return useQuery({
    queryKey: ['scan-analysis-v1', 'invalidate cache jan 18 2025', analysisId],
    queryFn: async () => {
      const response = await fetch(`/api/v1/analyses/${analysisId}`)

      if (!response.ok) {
        throw new ApiError(response.status)
      }

      const data = await response.json()
      return ScanAnalysis.parse(data)
    },
    enabled,
  })
}

export const useGetFindingArticle = ({
  analysisId,
  findingId,
  enabled,
}: {
  analysisId: string
  findingId: string
  enabled: boolean
}) => {
  return useQuery<string, ApiError>({
    queryKey: ['findingArticle', analysisId, findingId],
    queryFn: async () => {
      const response = await fetch(`/api/v1/analyses/${analysisId}/findings/${findingId}/article`)

      if (!response.ok) {
        let errorText: string | undefined
        try {
          errorText = await response.text()
        } catch (e) {}

        throw new ApiError(response.status, errorText)
      }

      const data = await response.text()
      return z.string().parse(data)
    },
    enabled,
    retry: 1,
  })
}

export const usePostUploadScan = ({
  onSuccess,
  onError,
  retry,
}: { onSuccess?: any; onError?: any; retry?: number } = {}) => {
  return useMutation({
    mutationFn: async ({ repositoryId, body }: { repositoryId: string; body: CreateScanRequestFormData }) => {
      const { response, data, error } = await client.POST(`/api/v1/repositories/{repository_id}/scans`, {
        params: { path: { repository_id: repositoryId } },
        // @ts-expect-error: open api annotations need to be updated
        body,
        bodySerializer: (body): FormData => {
          if (!body || !body.metadata || !body.files) {
            throw new Error('Metadata and files are required')
          }
          const formData = new FormData()
          formData.append(
            'metadata',
            new Blob(
              [JSON.stringify({ ...body.metadata, sha: body.metadata.sha === '' ? undefined : body.metadata.sha })],
              { type: 'application/json' }
            )
          )
          Object.entries(body.files).forEach(([_, file]) => {
            formData.append('files', file)
          })
          return formData
        },
      })
      if (response.status !== 201) {
        throw new ApiError(response.status, error)
      }
      return CreateScanResponse.parse({ scan_id: data?.uuid })
    },
    onSuccess,
    onError,
    retry,
  })
}

export const usePostAnalyzeScan = ({
  onSuccess,
  onError,
  retry,
}: { onSuccess?: any; onError?: any; retry?: number } = {}) => {
  return useMutation({
    mutationFn: async ({ scanId }: { scanId: string }) => {
      const { response, data, error } = await client.POST('/api/v1/scans/{scan_id}/analyses', {
        params: { path: { scan_id: scanId } },
      })
      if (response.status !== 201) {
        throw new ApiError(response.status, error)
      }
      const analysisSchema = z.object({
        id: z.string(),
      })
      const parsedData = analysisSchema.parse(data)
      return { analysis_id: parsedData.id }
    },
    onSuccess,
    onError,
    retry,
  })
}

export const usePostAddRepository = ({ onSuccess, onError }: { onSuccess?: any; onError?: any } = {}) => {
  return useMutation({
    mutationFn: async ({ name, credentials, url }: { name: string; credentials: Credentials; url: string }) => {
      const { response, error } = await client.POST('/api/v1/repositories', {
        body: {
          type: 'git',
          name,
          credentials,
          url,
        },
      })
      if (response.status !== 201) {
        throw new ApiError(response.status, error)
      }
    },
    onSuccess,
    onError,
  })
}

export const usePostAddAzureRepository = ({ onSuccess, onError }: { onSuccess?: any; onError?: any } = {}) => {
  return useMutation({
    mutationFn: async ({ organization, project, name }: { organization: string; project: string; name: string }) => {
      const { response, error } = await client.POST('/api/v1/repositories', {
        body: {
          type: 'azure',
          organization,
          project,
          name,
        },
      })
      if (response.status !== 201) {
        throw new ApiError(response.status, error)
      }
    },
    onSuccess,
    onError,
  })
}

export const useGetFindings = ({
  analysisId,
  pageNumber,
  pageSize,
  hasFix,
  hasNoFix,
}: {
  analysisId: string
  pageNumber: number
  pageSize: number
  hasFix?: boolean
  hasNoFix?: boolean
}) => {
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: ['hal-findings', analysisId, pageNumber, pageSize, hasFix, hasNoFix],
    queryFn: async () => {
      const params = new URLSearchParams({
        page_number: pageNumber.toString(),
        page_size: pageSize.toString(),
      })

      if (hasFix) {
        params.append('min_fix_count', '1')
      }

      if (hasNoFix) {
        params.append('max_fix_count', '0')
      }

      const response = await fetch(`/api/v1/analyses/${analysisId}/findings?${params.toString()}`, {
        headers: {
          Accept: 'application/hal+json',
        },
      })

      if (!response.ok) {
        throw new ApiError(response.status)
      }

      const data = await response.json()
      return PaginatedFindings.parse(data)
    },
    staleTime: 0,
    gcTime: 0,
  })
}

export const useGetFixesForFinding = ({
  analysisId,
  findingId,
  pageNumber = 0,
  pageSize = 10,
  enabled,
}: {
  analysisId: string
  findingId: string
  pageNumber?: number
  pageSize?: number
  enabled: boolean
}) => {
  return useQuery({
    queryKey: ['fixesForFinding', analysisId, findingId, pageNumber, pageSize],
    queryFn: async () => {
      const response = await fetch(
        `/api/v1/analyses/${analysisId}/findings/${findingId}/fixes?page_number=${pageNumber}&page_size=${pageSize}`,
        {
          headers: {
            Accept: 'application/hal+json',
          },
        }
      )

      const data = await response.json()
      return PaginatedFixes.parse(data)
    },
    enabled,
  })
}

export const useGetChangesetsForFix = ({
  fixId,
  pageNumber = 0,
  pageSize = 10,
  enabled,
}: {
  fixId: string
  pageNumber?: number
  pageSize?: number
  enabled: boolean
}) => {
  return useQuery({
    queryKey: ['changesetsForFix', fixId, pageNumber, pageSize],
    queryFn: async () => {
      const response = await fetch(
        `/api/v1/fixes/${fixId}/changesets?page_number=${pageNumber}&page_size=${pageSize}`,
        {
          headers: {
            Accept: 'application/hal+json',
          },
        }
      )

      const data = await response.json()
      return PaginatedChangesets.parse(data)
    },
    enabled,
  })
}

export const useGetAnalyses = ({
  repositoryId,
  pageNumber = 0,
  pageSize = 10,
}: {
  repositoryId: string
  pageNumber?: number
  pageSize?: number
}) => {
  return useQuery({
    queryKey: ['analyses', repositoryId, pageNumber, pageSize],
    queryFn: async () => {
      const { response, data } = await client.GET('/api/v1/repositories/{repository_id}/analyses', {
        params: {
          path: { repository_id: repositoryId },
          query: {
            page_number: pageNumber,
            page_size: pageSize,
          },
        },
      })

      if (response.status === 404) {
        throw new Error('Repository not found')
      }

      if (response.status !== 200) {
        throw new Error(`API request failed with status ${response.status}`)
      }

      return PaginatedAnalysisResponse.parse(data)
    },
  })
}

export const usePostPatch = ({
  onSuccess,
  onError,
  retry,
}: { onSuccess?: any; onError?: any; retry?: number } = {}) => {
  return useMutation({
    mutationFn: async ({
      analysisId,
      createPatchRequest,
    }: {
      analysisId: string
      createPatchRequest: CreatePatchRequest
    }) => {
      const parsedCreatePatchRequest = CreatePatchRequest.parse(createPatchRequest)

      const response = await fetch(`/api/v1/analyses/${analysisId}/patches`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(parsedCreatePatchRequest),
      })

      if (response.status !== 201) {
        let errorText: string | undefined
        try {
          errorText = await response.text()
        } catch (e) {}

        throw new ApiError(response.status, errorText)
      }

      const location = response.headers.get('Location')
      if (!location) {
        throw new Error('No Location header in response')
      }

      return { location }
    },
    onSuccess,
    onError,
    retry,
  })
}

export const useGetAzureOrganizations = ({
  pageNumber = 0,
  pageSize = 10,
}: {
  pageNumber?: number
  pageSize?: number
}) => {
  let { data, ...rest } = useQuery({
    queryKey: ['azure-organizations', pageNumber, pageSize],
    queryFn: async () => {
      const response = await fetch(
        `/api/v1/integrations/azure-default/organizations?page_number=${pageNumber}&page_size=${pageSize}`
      )

      if (!response.ok) {
        throw new ApiError(response.status)
      }

      const data = await response.json()
      return HALPaginatedAzureOrganizations.parse(data)
    },
  })

  data = useMemo(
    () => data ?? { total: 0, page: { number: 0, size: 0 }, _embedded: { items: [] }, _links: { self: { href: '' } } },
    [data]
  )
  return { data, ...rest }
}

export const useGetAzureProjects = ({
  organizationName,
  pageNumber = 0,
  pageSize = 200,
  enabled = true,
}: {
  organizationName: string
  pageNumber?: number
  pageSize?: number
  enabled?: boolean
}) => {
  let { data, ...rest } = useQuery({
    queryKey: ['azure-projects', organizationName, pageNumber, pageSize],
    queryFn: async () => {
      const response = await fetch(
        `/api/v1/integrations/azure-default/${organizationName}/projects?page_number=${pageNumber}&page_size=${pageSize}`
      )

      if (!response.ok) {
        throw new ApiError(response.status)
      }

      const data = await response.json()
      return HALPaginatedAzureProjects.parse(data)
    },
    enabled: enabled && !!organizationName,
  })

  data = useMemo(
    () => data ?? { total: 0, page: { number: 0, size: 0 }, _embedded: { items: [] }, _links: { self: { href: '' } } },
    [data]
  )
  return { data, ...rest }
}

export const useGetAzureRepositories = ({
  organizationName,
  projectId,
  pageNumber = 0,
  pageSize = 200,
  enabled = true,
}: {
  organizationName: string
  projectId: string
  pageNumber?: number
  pageSize?: number
  enabled?: boolean
}) => {
  let { data, ...rest } = useQuery({
    queryKey: ['azure-repositories', organizationName, projectId, pageNumber, pageSize],
    queryFn: async () => {
      const response = await fetch(
        `/api/v1/integrations/azure-default/${organizationName}/projects/${projectId}/repositories?page_number=${pageNumber}&page_size=${pageSize}`
      )

      if (!response.ok) {
        throw new ApiError(response.status)
      }

      const data = await response.json()
      return HALPaginatedAzureRepositories.parse(data)
    },
    enabled: enabled && !!organizationName && !!projectId,
  })

  data = useMemo(
    () => data ?? { total: 0, page: { number: 0, size: 0 }, _embedded: { items: [] }, _links: { self: { href: '' } } },
    [data]
  )
  return { data, ...rest }
}

export const useGetAllAnalyses = ({
  states,
  pageNumber = 0,
  pageSize = 10,
  enabled = true,
}: {
  states?: Array<'queued' | 'in_progress' | 'skipped' | 'completed_results' | 'completed_no_results' | 'failed'>
  pageNumber?: number
  pageSize?: number
  enabled?: boolean
} = {}) => {
  return useQuery({
    queryKey: ['all-analyses', states, pageNumber, pageSize],
    queryFn: async () => {
      const queryParams = new URLSearchParams({
        page_number: pageNumber.toString(),
        page_size: pageSize.toString(),
      })

      if (states?.length) {
        states.forEach(state => queryParams.append('states', state))
      }

      const response = await fetch(`/api/v1/analyses?${queryParams}`, {
        headers: {
          Accept: 'application/hal+json',
        },
      })

      if (!response.ok) {
        throw new ApiError(response.status)
      }

      const data = await response.json()
      return PaginatedAnalysisResponse.parse(data)
    },
    enabled,
  })
}
