import { GitMergeIcon, MarkGithubIcon } from '@primer/octicons-react'
import * as Dialog from '@radix-ui/react-dialog'
import { useQueryClient } from '@tanstack/react-query'
import {
  ColumnDef,
  RowSelectionState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useLocalStorage } from '@uidotdev/usehooks'
import classNames from 'classnames'
import fuzzysort from 'fuzzysort'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { match } from 'ts-pattern'
import { DefaultButton } from '../components/default-button.tsx'
import { UploadScanFormDrawer } from '../components/drawer/upload-scan-form-drawer.tsx'
import { LatestAnalysisLink } from '../components/latest-analysis-link'
import { OnboardingDialog } from '../components/onboarding-dialog.tsx'
import { PaginationControls } from '../components/pagination-controls.tsx'
import { SelectBox } from '../components/select-box.tsx'
import AzureLightIcon from '../components/svg/azure-light.svg?react'
import GitlabIcon from '../components/svg/gitlab-icon.svg?react'
import SonarWhiteIcon from '../components/svg/sonar-white-icon.svg?react'
import UploadIcon from '../components/svg/upload-icon.svg?react'
import { TableBodySkeleton } from '../components/table-body-skeleton.tsx'
import { Pagination } from '../components/table.tsx'
import { TextInput } from '../components/text-input.tsx'
import { TooltipWithHover } from '../components/tooltip'
import {
  useGetAnalyses,
  useGetAzureOrganizations,
  useGetAzureProjects,
  useGetAzureRepositories,
  useGetRepositoriesV1,
  usePostAddAzureRepository,
  usePostAddRepository,
  usePostAnalyzeScan,
  usePostUploadScan,
} from '../utils/api-client/user-platform-api-hooks.ts'
import {
  AnyRepositoryResponse,
  PaginatedResponseRepositoryResponse,
} from '../utils/api-client/user-platform-api-schemas.ts'
import { useAddToast } from '../utils/higher-order-components/with-toasts.tsx'
import { useEnvironmentData } from '../utils/hooks/use-environment-data.ts'
import * as styles from './repositories-page.css.ts'

export function RepositoriesPage() {
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  })

  const { data: repositories, isPending: repositoriesIsPending } = useGetRepositoriesV1({
    pageNumber: pagination.pageIndex,
    pageSize: pagination.pageSize,
  })

  const environmentData = useEnvironmentData()

  const [selectedRepositoryId, setSelectedRepositoryId] = useState<string | null>(null)
  const selectedRepositoryDisplayName = repositories?.items.find(
    repository => repository.id === selectedRepositoryId
  )?.name

  const handleSonarImport = useSonarImport()

  const [isOnboardingDialogOpen, setIsOnboardingDialogOpen] = useLocalStorage('isOnboardingDialogOpen', true)
  const handleCompleteOnboarding = useCallback(() => {
    setIsOnboardingDialogOpen(false)
  }, [setIsOnboardingDialogOpen])

  return (
    <div className={styles.mainContainer}>
      <RepositoriesPageImpure
        repositories={repositories}
        repositoriesIsPending={repositoriesIsPending}
        pagination={pagination}
        setPagination={setPagination}
        environmentData={environmentData}
        selectedRepositoryId={selectedRepositoryId}
        setSelectedRepositoryId={setSelectedRepositoryId}
        selectedRepositoryDisplayName={selectedRepositoryDisplayName}
        handleSonarImport={handleSonarImport}
        handleCompleteOnboarding={handleCompleteOnboarding}
        isOnboardingDialogOpen={isOnboardingDialogOpen}
      />
    </div>
  )
}

type RepositoriesPageImpure = {
  repositories: PaginatedResponseRepositoryResponse
  repositoriesIsPending: boolean
  pagination: Pagination
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>
  environmentData: ReturnType<typeof useEnvironmentData>
  selectedRepositoryId: string | null
  setSelectedRepositoryId: React.Dispatch<React.SetStateAction<string | null>>
  selectedRepositoryDisplayName: string | undefined
  handleSonarImport: (repositoryId: string) => void
  handleCompleteOnboarding: () => void
  isOnboardingDialogOpen: boolean
}

export function RepositoriesPageImpure({
  repositories,
  repositoriesIsPending,
  pagination,
  setPagination,
  environmentData,
  selectedRepositoryId,
  setSelectedRepositoryId,
  selectedRepositoryDisplayName,
  handleSonarImport,
  handleCompleteOnboarding,
  isOnboardingDialogOpen,
}: RepositoriesPageImpure) {
  return (
    <div className={styles.topLevelContainer}>
      {isOnboardingDialogOpen && <OnboardingDialog handleCompleteOnboarding={handleCompleteOnboarding} />}
      {selectedRepositoryId && selectedRepositoryDisplayName && (
        <UploadScanFormDrawer
          repositoryId={selectedRepositoryId}
          repositoryDisplayName={selectedRepositoryDisplayName}
          handleCloseDrawer={() => setSelectedRepositoryId(null)}
        />
      )}
      <RepositoriesTable
        repositories={repositories}
        repositoriesIsPending={repositoriesIsPending}
        pagination={pagination}
        setPagination={setPagination}
        environmentData={environmentData}
        setSelectedRepositoryId={setSelectedRepositoryId}
        handleSonarImport={handleSonarImport}
      />
    </div>
  )
}

function RepositoriesTable({
  repositories,
  repositoriesIsPending,
  pagination,
  setPagination,
  environmentData,
  setSelectedRepositoryId,
  handleSonarImport,
}: {
  repositories: PaginatedResponseRepositoryResponse
  repositoriesIsPending: boolean
  pagination: Pagination
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>
  environmentData: ReturnType<typeof useEnvironmentData>
  setSelectedRepositoryId: React.Dispatch<React.SetStateAction<string | null>>
  handleSonarImport: (repositoryId: string) => void
}) {
  const columns = useMemo<ColumnDef<AnyRepositoryResponse>[]>(
    () => createColumnsDefinitions(setSelectedRepositoryId, handleSonarImport),
    [setSelectedRepositoryId, handleSonarImport]
  )
  const [globalFilter, setGlobalFilter] = useState('')
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const table = useReactTable({
    getRowId: data => String(data.id),
    data: repositories.items,
    columns,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      globalFilter,
      rowSelection,
    },
    globalFilterFn: (row, columnId, value) => {
      const results = fuzzysort.go(value, [row.getValue(columnId)])
      return results.length > 0
    },
    filterFns: {
      select: (row, columnId, selectedValues) => {
        const columnValue: any = row.getValue(columnId)
        return selectedValues.some((value: any) => value === columnValue)
      },
    },
  })

  return (
    <>
      <div className={styles.tableControls}>
        <div>
          <h4 className={styles.tableHeading}>
            Repositories <span className={styles.installationsCount}>({repositories.total})</span>
          </h4>
          <p className={styles.tableDescription}>
            Locations where {environmentData.githubAppName} has been configured for analysis
          </p>
        </div>

        <div className={styles.tableControlsRight}>
          <TextInput value={globalFilter ?? ''} onChange={value => setGlobalFilter(String(value))} size="small" />
          <AddRepositoryModal />
        </div>
      </div>

      <table className={styles.table}>
        <thead className={styles.tableHead}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                    key={header.id}
                    className={classNames(styles.columnHeader, styles.cell, styles.installationsTableCell)}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        {repositoriesIsPending ? (
          <TableBodySkeleton rowCount={7} columnCount={columns.length} rowHeightInPixels={43} />
        ) : (
          <tbody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <tr key={row.id} className={styles.row}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className={classNames(styles.cell, styles.installationsTableCell)}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td className={classNames(styles.cell, styles.emptyStateCell)} colSpan={columns.length}>
                  <div className={styles.emptyStateText}>
                    No repositories found. Please connect your first repository.
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>

      <div className={styles.tableFooter}>
        <PaginationControls
          tableLabel="Installations"
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          rowCount={repositories.total}
          onNextPage={() => {
            setPagination(prevState => ({
              pageIndex: prevState.pageIndex + 1,
              pageSize: prevState.pageSize,
            }))
          }}
          onPreviousPage={() =>
            setPagination(prevState => ({
              pageIndex: prevState.pageIndex - 1,
              pageSize: prevState.pageSize,
            }))
          }
          onChangePageSize={value => setPagination({ pageIndex: 0, pageSize: value })}
        />
      </div>
    </>
  )
}

function RepoLocation({ repository }: { repository: AnyRepositoryResponse }) {
  //  const activeVariant = isActive ? 'active' : 'inactive'
  const SCMIcon = match(repository.type)
    .with('azure', () => {
      return <AzureLightIcon className={styles.scmIcon} />
    })
    .with('github', () => {
      return <MarkGithubIcon className={styles.scmIcon} />
    })
    .with('git', () => {
      return <GitMergeIcon aria-label="Git icon" className={styles.scmIcon} />
    })
    .with('gitlab', () => {
      return <GitlabIcon aria-label="Gitlab icon" className={styles.scmIcon} />
    })
    .otherwise(() => {
      return <GitMergeIcon aria-label="Git icon" className={styles.scmIcon} />
    })

  return (
    <span className={styles.repositoryName}>
      {SCMIcon}
      {repository.name}
    </span>
  )
}

const useSonarImport = () => {
  const navigate = useNavigate()
  const { handleAddToastWithTimeout } = useAddToast()

  const analyzeScanMutation = usePostAnalyzeScan({
    onError: (error: any) => {
      handleAddToastWithTimeout({
        message: <>Analysis trigger failed. You can try to analyze it again later. {error.message}.</>,
        variant: 'error',
      })
    },
  })

  const uploadScanMutation = usePostUploadScan({
    onSuccess: ({ scan_id }) => {
      handleAddToastWithTimeout({
        message: <>Sonar import started successfully.</>,
        variant: 'success',
      })

      analyzeScanMutation.mutate(
        { scanId: scan_id },
        {
          onSuccess: ({ analysis_id }) => navigate(`/analysis/${analysis_id}/${scan_id}`),
        }
      )
    },
    onError: (error: any) => {
      handleAddToastWithTimeout({
        message: <>{`Unable to import Sonar findings: ${error.message}. Please try again.`}</>,
        variant: 'error',
      })
    },
  })

  return useCallback(
    (repositoryId: string) => {
      const data = {
        repositoryId,
        metadata: {
          tool: 'sonar' as const,
          sha: '',
        },
        files: {},
      }

      uploadScanMutation.mutate({ repositoryId, body: data })
    },
    [uploadScanMutation]
  )
}

const createColumnsDefinitions = (
  setSelectedRepositoryId: React.Dispatch<React.SetStateAction<string | null>>,
  handleSonarImport: (repositoryId: string) => void
): ColumnDef<AnyRepositoryResponse>[] => {
  const columnHelper = createColumnHelper<AnyRepositoryResponse>()

  return [
    columnHelper.accessor((row: AnyRepositoryResponse): string => row.name, {
      id: 'repository',
      cell: ({ row }) => {
        return <RepoLocation repository={row.original} />
      },
      header: () => <span>REPOSITORY</span>,
    }) as ColumnDef<AnyRepositoryResponse>,
    columnHelper.display({
      id: 'fixes',
      cell: () => <></>,
      header: () => <></>,
    }),
    columnHelper.display({
      id: 'analysis',
      cell: ({ row }) => {
        const { data: analyses } = useGetAnalyses({
          repositoryId: row.original.id,
          pageSize: 1,
          pageNumber: 0,
        })
        const latestAnalysis = analyses?._embedded?.items?.[0]

        return (
          <div className={styles.center}>
            {latestAnalysis ? (
              <LatestAnalysisLink latestAnalysis={latestAnalysis} />
            ) : (
              <a
                href="#"
                className={styles.uploadScanLink}
                onClick={e => {
                  e.preventDefault()
                  setSelectedRepositoryId(row.original.id)
                }}
              >
                Upload scan
                <UploadIcon className={styles.uploadIconThemed} />
              </a>
            )}
          </div>
        )
      },
      header: ({}) => <span className={styles.center}>LATEST ANALYSIS</span>,
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => {
        const { data: analyses } = useGetAnalyses({
          repositoryId: row.original.id,
          pageSize: 1,
          pageNumber: 0,
        })
        const latestAnalysis = analyses?._embedded?.items?.[0]

        return (
          <div className={styles.center}>
            {latestAnalysis && (
              <TooltipWithHover
                trigger={
                  <button className={styles.actionButton} onClick={() => setSelectedRepositoryId(row.original.id)}>
                    <UploadIcon className={styles.uploadIconThemed} />
                  </button>
                }
                size="small"
              >
                Upload scan
              </TooltipWithHover>
            )}
            <TooltipWithHover
              trigger={
                <button
                  className={styles.actionButton}
                  onClick={() => handleSonarImport(row.original.id)}
                  aria-label="Import Sonar findings"
                >
                  <SonarWhiteIcon />
                </button>
              }
              size="small"
            >
              Import Sonar findings
            </TooltipWithHover>
          </div>
        )
      },
      header: () => 'Actions',
    }),
  ]
}

const AddRepositoryModal: React.FC<{}> = () => {
  const { handleAddToastWithTimeout } = useAddToast()
  const [selectedConnectionType, setSelectedConnectionType] = useState('pixeebotApp')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [url, setUrl] = useState('')
  const [addRepositoryDialogOpen, setAddRepositoryDialogOpen] = useState(false)
  const queryClient = useQueryClient()
  const addRepositoryMutation = usePostAddRepository({
    onSuccess: () => {
      handleAddToastWithTimeout({ message: <>Repository was successfully connected.</>, variant: 'success' })
      queryClient.invalidateQueries({ queryKey: ['repositoriesV1'] })
      setAddRepositoryDialogOpen(false)
    },
    onError: (error: any) => {
      console.error(error)
      handleAddToastWithTimeout({
        message: <>Repository connection failed with error: {error.message}.</>,
        variant: 'error',
      })
      if (error.bodyAsText && typeof error.bodyAsText === 'string') {
        handleAddToastWithTimeout({
          message: <pre style={{ margin: '0px' }}>{error.bodyAsText}</pre>,
          variant: 'error',
        })
      }
    },
  })
  const extractRepoName = (url: string): string => {
    const match = url.match(/\/([^\/]+)\.git$/)
    return match ? (match[1] ?? url) : url
  }

  const SelectedRadioSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect width="16" height="16" rx="8" fill="#C10B5E" />
      <circle cx="8" cy="8" r="3" fill="white" />
    </svg>
  )

  const UnselectedRadioSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#AEABB6" />
    </svg>
  )

  const handleConnectClick = useCallback(() => {
    addRepositoryMutation.mutate(
      {
        name: extractRepoName(url),
        credentials: {
          username,
          password,
        },
        url,
      },
      {
        onSuccess: () => {
          setUrl('')
          setUsername('')
          setPassword('')
        },
      }
    )
  }, [url, username, password, addRepositoryMutation])

  const [selectedOrganization, setSelectedOrganization] = useState<string | undefined>()
  const [selectedProject, setSelectedProject] = useState<string | undefined>()
  const [selectedRepository, setSelectedRepository] = useState<string | undefined>()

  const handleOrganizationChange = (value: string) => {
    setSelectedOrganization(value)
    setSelectedProject('')
    setSelectedRepository('')
  }

  const handleProjectChange = (value: string) => {
    setSelectedProject(value)
    setSelectedRepository('')
  }

  const { data: azureOrganizations } = useGetAzureOrganizations({})
  const { data: azureProjects } = useGetAzureProjects({
    organizationName: selectedOrganization ?? '',
    enabled: !!selectedOrganization,
  })
  const { data: azureRepositories } = useGetAzureRepositories({
    organizationName: selectedOrganization ?? '',
    projectId: selectedProject ?? '',
    enabled: !!selectedOrganization && !!selectedProject,
  })

  const azureOrganizationOptions = useMemo(
    () => azureOrganizations?._embedded?.items?.map(org => ({ value: org.name, label: org.name })) ?? [],
    [azureOrganizations]
  )

  const azureProjectOptions = useMemo(
    () => azureProjects?._embedded?.items?.map(project => ({ value: project.id, label: project.name })) ?? [],
    [azureProjects]
  )

  const azureRepositoryOptions = useMemo(
    () =>
      azureRepositories?._embedded?.items?.map(repo => ({
        value: repo.id,
        label: repo.name,
        disabled: repo.pixee_id !== null,
      })) ?? [],
    [azureRepositories]
  )

  const addAzureRepositoryMutation = usePostAddAzureRepository({
    onSuccess: () => {
      handleAddToastWithTimeout({ message: <>Repository was successfully connected.</>, variant: 'success' })
      queryClient.invalidateQueries({ queryKey: ['repositoriesV1'] })
      setAddRepositoryDialogOpen(false)
    },
    onError: (error: any) => {
      console.error(error)
      handleAddToastWithTimeout({
        message: <>Repository connection failed with error: {error.message}.</>,
        variant: 'error',
      })
      if (error.bodyAsText && typeof error.bodyAsText === 'string') {
        handleAddToastWithTimeout({
          message: <pre style={{ margin: '0px' }}>{error.bodyAsText}</pre>,
          variant: 'error',
        })
      }
    },
  })

  const handleIntegrationConnectClick = useCallback(() => {
    if (
      selectedConnectionType === 'existingIntegration' &&
      selectedOrganization &&
      selectedProject &&
      selectedRepository
    ) {
      const repository = azureRepositories?._embedded?.items?.find(repo => repo.id === selectedRepository)
      const project = azureProjects?._embedded?.items?.find(proj => proj.id === selectedProject)
      if (repository && project) {
        addAzureRepositoryMutation.mutate({
          organization: selectedOrganization,
          project: project.name,
          name: repository.name,
        })
      }
    }
  }, [
    selectedConnectionType,
    selectedOrganization,
    selectedProject,
    selectedRepository,
    azureRepositories,
    azureProjects,
    addAzureRepositoryMutation,
  ])

  return (
    <Dialog.Root open={addRepositoryDialogOpen} onOpenChange={setAddRepositoryDialogOpen}>
      <Dialog.Trigger asChild>
        <DefaultButton size="small">+ &nbsp; Connect new</DefaultButton>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.dialogOverlay} />
        <Dialog.Content className={styles.dialogContent} aria-describedby={undefined}>
          <Dialog.Title className={styles.title}>New repository</Dialog.Title>
          <form>
            <div className={styles.radioGroup}>
              <label className={styles.radioButton}>
                <input
                  type="radio"
                  name="connectionType"
                  value="pixeebotApp"
                  checked={selectedConnectionType === 'pixeebotApp'}
                  onChange={() => setSelectedConnectionType('pixeebotApp')}
                  className={styles.hiddenRadioButton}
                />
                {selectedConnectionType === 'pixeebotApp' ? <SelectedRadioSVG /> : <UnselectedRadioSVG />}
                Install the Pixeebot GitHub application (GitHub repositories only)
              </label>
              <label className={styles.radioButton}>
                <input
                  type="radio"
                  name="connectionType"
                  value="gitClient"
                  checked={selectedConnectionType === 'gitClient'}
                  onChange={() => setSelectedConnectionType('gitClient')}
                  className={styles.hiddenRadioButton}
                />
                {selectedConnectionType === 'gitClient' ? <SelectedRadioSVG /> : <UnselectedRadioSVG />}
                Connect via Git client (clone a repository from any SCM)
              </label>
              <label className={styles.radioButton}>
                <input
                  type="radio"
                  name="connectionType"
                  value="existingIntegration"
                  checked={selectedConnectionType === 'existingIntegration'}
                  onChange={() => setSelectedConnectionType('existingIntegration')}
                  className={styles.hiddenRadioButton}
                />
                {selectedConnectionType === 'existingIntegration' ? <SelectedRadioSVG /> : <UnselectedRadioSVG />}
                Connect to repositories from an existing integration
              </label>
            </div>
            {selectedConnectionType === 'existingIntegration' ? (
              <div className={styles.inputGroup}>
                <SelectBox
                  label="Integration"
                  value="azureDevOps"
                  disabled
                  options={[
                    {
                      value: 'azureDevOps',
                      label: (
                        <span className={styles.selectItemText}>
                          <AzureLightIcon className={styles.scmIcon} />
                          Azure DevOps
                        </span>
                      ),
                    },
                  ]}
                />
                <SelectBox
                  label="Organization"
                  value={selectedOrganization}
                  onValueChange={handleOrganizationChange}
                  placeholder="Select organization"
                  options={azureOrganizationOptions}
                />
                <SelectBox
                  label="Project"
                  value={selectedProject}
                  onValueChange={handleProjectChange}
                  disabled={!selectedOrganization}
                  placeholder="Select project"
                  options={azureProjectOptions}
                />
                <SelectBox
                  label="Available repositories"
                  value={selectedRepository}
                  onValueChange={setSelectedRepository}
                  disabled={!selectedProject}
                  placeholder="Select repository"
                  options={azureRepositoryOptions}
                />
              </div>
            ) : (
              selectedConnectionType !== 'pixeebotApp' && (
                <div className={styles.inputGroup}>
                  <label className={styles.inputLabel}>
                    URL
                    <span className={styles.requiredAsterisk} title="required">
                      *
                    </span>
                    <input
                      type="text"
                      name="url"
                      placeholder="ex: https://scm.com/org/repo-lang.git"
                      className={styles.inputField}
                      value={url}
                      onChange={e => setUrl(e.target.value)}
                    />
                  </label>
                  <label className={styles.inputLabel}>
                    Username
                    <input
                      type="text"
                      name="username"
                      placeholder="Enter username"
                      className={styles.inputField}
                      value={username}
                      onChange={e => setUsername(e.target.value)}
                    />
                  </label>
                  <label className={styles.inputLabel}>
                    Password
                    <input
                      type="password"
                      name="password"
                      placeholder="Enter password"
                      className={styles.inputField}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </label>
                </div>
              )
            )}
            <div className={styles.buttonGroup}>
              <Dialog.Close asChild>
                <DefaultButton buttonType="secondary">Cancel</DefaultButton>
              </Dialog.Close>
              {selectedConnectionType === 'pixeebotApp' ? (
                <DefaultButton href="https://github.com/apps/pixeebot/installations/new" buttonType="primary">
                  Go to GitHub
                </DefaultButton>
              ) : selectedConnectionType === 'existingIntegration' ? (
                <DefaultButton
                  buttonType="primary"
                  state={selectedRepository ? 'default' : 'disabled'}
                  onClick={handleIntegrationConnectClick}
                >
                  Connect
                </DefaultButton>
              ) : (
                <DefaultButton buttonType="primary" state={url ? 'default' : 'disabled'} onClick={handleConnectClick}>
                  Connect
                </DefaultButton>
              )}
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
